.switch-filter {
  minWidth: 65;
  width: 160px;
  margin: 5px;
  textAlign: left;
  marginLeft: 0; }

.span-switch-filter {
  position: absolute;
  right: 4px;
  top: 0;
  fontSize: 116%; }

.select-option-logo {
  height: 20px; }

.styled-switch {
  height: 40px;
  margin: auto;
  background: transparent;
  border: 0;
  font-size: 13px; }
  .styled-switch.margin-top {
    margin-top: 25px; }
  .styled-switch.margin-bottom {
    margin-bottom: 50px; }
  .styled-switch .ant-menu-item {
    height: 40px;
    width: 200px;
    line-height: 32px;
    text-align: center;
    margin: 0 5px;
    border-radius: 4px;
    border: 0.5px solid #2D2D2D; }
    .styled-switch .ant-menu-item:not(.ant-menu-item-selected):hover {
      background: #17aab5 !important; }
      .styled-switch .ant-menu-item:not(.ant-menu-item-selected):hover:after {
        content: none; }
  .styled-switch .ant-menu-item-selected, .styled-switch .ant-menu-item-selected:hover, .styled-switch .ant-menu-item:hover {
    background: #0097a2;
    color: #FFFFFF;
    border: 0.5px solid #0097a2; }
    .styled-switch .ant-menu-item-selected:after, .styled-switch .ant-menu-item-selected:hover:after, .styled-switch .ant-menu-item:hover:after {
      content: ' ';
      display: block;
      margin: auto;
      height: 2px;
      width: 40px;
      background: #FFFFFF;
      position: relative;
      bottom: 3.5px; }

.btn-pre-head {
  margin-right: 10px;
  padding-bottom: 15px;
  justify-content: flex-end; }

.info-upload-maestro {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 12px;
  border: dotted cadetblue 1px;
  display: grid;
  justify-content: center;
  font-style: italic; }
  .info-upload-maestro section {
    margin: 0 5px;
    background: red; }

.upload-maestro {
  display: flex;
  flex-direction: column; }

.img-maestro-form {
  background-color: #FAFAFA;
  margin: auto;
  width: 400px;
  height: auto;
  display: block; }

h4.titre-demande-en-cours {
  text-align: center;
  font-style: italic; }

.p-phrase-intro-maestro {
  margin-top: 15px; }

.demande-traitement-link {
  margin: 5px 0;
  width: 100%; }
  .demande-traitement-link.traitee .demande-traitement-item {
    background-color: rgba(234, 238, 240, 0.55); }
    .demande-traitement-link.traitee .demande-traitement-item .ant-list-item-meta.statut .ant-list-item-meta-description {
      font-weight: 700;
      color: #00939e; }

.demande-traitement-item {
  outline: rgba(0, 0, 0, 0) solid 1px;
  margin: 0; }

.demande-traitement-item:hover {
  outline: rgba(0, 0, 0, 0.3) solid 1px;
  cursor: pointer;
  transition: 150ms ease-out;
  background-color: rgba(234, 238, 240, 0.73); }

.p-liste-contraintes {
  display: grid;
  justify-content: center; }

.liste-demandes {
  display: flex;
  width: 1080px; }
